export { wrapRootElement } from './src/apollo/wrap-root-element';

// bootstrap
import 'bootstrap/dist/css/bootstrap.css';

// app global styles
import './src/assets/scss/main.scss';

export const onServiceWorkerUpdateReady = () => {
	console.log(
		`This application has been updated. ` +
		`Reload to display the latest version?`
	);
};
