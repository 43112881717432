// https://github.com/jlengstorf/gatsby-with-apollo
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import fetch from 'isomorphic-fetch';

const getEnvironment = () => {
	// By host
	if (typeof window !== 'undefined' && window.location) {
		return ['www.izracun-kredita.si', 'izracun-kredita.si'].includes(window.location.host) ? 'production' : 'development';
	}

	// Default
	return process.env.NODE_ENV;
};

const httpLink = createHttpLink({
	uri: getEnvironment() === 'development' ? 'https://dev06.pot-chronos-1.net/graphql' : 'https://api.izracun-kredita.si/graphql'
});

const authLink = setContext((_, { headers }) => {
	const environment = getEnvironment();
	console.log('Environment', environment);

	return {
		headers: {
			...headers,
			Environment: environment,
			Apikey: '697c03cb-2fe1-4468-84f1-3f91d240a121'
		}
	};
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		graphQLErrors.forEach(({ message, locations, path }) => {
			console.log(`[GraphQL error]: ${message}, Location: ${locations}, Path: ${path}`);
		});
	}
});

export const client = new ApolloClient({
	link: authLink.concat(errorLink).concat(httpLink),
	cache: new InMemoryCache(),
	fetch
});
